<template>

  <section id="gas">

    <div class="row my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-circle-plus color-secondary"></i>
          Conoce las cifras más importantes de Beneficios
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-6 col-lg-6 input-search">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar colaborador" 
                v-model.trim="user_name" 
                @keyup.enter="getPaginas()"/>
                <span class="input-search-icon" @click="getPaginas()">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col-6 col-lg-6">
            <v-select
              class="selvue-custom"
              :clearable="false"
              :options="data_estados" 
              v-model="sel_estados"
              placeholder="Categoría">
              <span slot="no-options"></span>
            </v-select>  
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-4 col-xxl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon icon="filter" class="color-secondary pe-2"/>
            Filtrar
          </div>
          <div class="col-10 col-lg-7 col-xl-7 col-xxl-7">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="init_date != 'Inicio'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
    
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="end_date != 'Término'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 col-lg-1 d-flex">
            <export-json-excel
              class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill"
              :data="data_excel"
              :fields="fields_excel"
              :name="name_excel">
              <font-awesome-icon icon="download"/>
            </export-json-excel>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="final_list!=null">
      <div class="col-12 col-lg-7">
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 d-flex align-items-center justify-content-between">
                    <h5 class="font-main-bold color-gray">
                      <i class="fa-solid fa-circle-user color-secondary"></i>
                      Solicitudes 
                    </h5>
                    <div class="form-check form-switch form-check-reverse">
                      <input 
                        v-model="all_check"
                        class="form-check-input link-cursor" 
                        type="checkbox"
                        id="seleccionarTodos" 
                        role="switch">
                        <label 
                          class="form-check-label link-cursor text-secondary" 
                          for="seleccionarTodos">
                          Seleccionar todos
                        </label>
                    </div>
                  </div>
                </div>
                <ul class="list-group list-group-flush scroll-custom-550 mt-3" :key="list_key">
                  <li
                    v-for="(ad, i) in final_list"
                    :key="ad.id_solicitud"
                    href="javascript:"
                    class="list-group-item"
                    :class="selectedUserList(ad.id_solicitud)"
                  >
                    <div class="d-flex align-items-top">
                      <div class="flex-shrink-0">
                        <label class="form-check-label input-check-custom">
                          <input 
                            :ref="`typeBox`"
                            v-model="checks"
                            :value="{
                              solicitud: ad.id_solicitud,
                              user: ad.usuario.id_usuario,
                            }"
                            class="form-check-input link-cursor"
                            type="checkbox"
                            > 
                        </label>
                      </div> 
                      <a 
                        href="javascript:" 
                        @click="selectSoli(ad, i)"
                        class="flex-grow-1 ms-3 text-decoration-none"
                      > 
                          <div class="row">
                            <div class="col-12 col-lg-6">
                              <div class="d-flex">
                                <div class="flex-shrink-0">
                                  <img 
                                    class="img-obj img-obj-60 img-obj-circle border" 
                                    :src="handleUserImage(ad.usuario.imagen_perfil)"
                                  />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                  <p class="text-secondary">
                                    {{ ad.usuario.primer_nombre | capitalize }}
                                    {{ ad.usuario.apellido_paterno | capitalize }}
                                    {{ ad.usuario.apellido_materno | capitalize }}<br>
                                    <small class="text-black-50">
                                      <i class="fa-solid fa-circle-user color-brown"></i>
                                      {{ ad.usuario.cargo[0].nombre_cargo }}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-lg-3 text-center">
                              <p class="font-size-14 text-secondary">
                                <i class="fa-solid fa-calendar-days color-secondary"></i>
                                {{ dateNewFormat(ad.fecha_creacion, 1) }}
                              </p>
                            </div>
                            <div class="col-6 col-lg-3 text-end">
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'En Proceso'"
                                class="font-size-14 badge badge-custom badge-custom-yellow-light">
                                <font-awesome-icon icon="clock"/>
                                En proceso
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Confirmada'"
                                class="font-size-14 badge badge-custom badge-custom-blue-light">
                                <font-awesome-icon icon="check"/>
                                Corfirmada
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Espera Retiro'"
                                class="font-size-14 badge badge-custom badge-custom-blue-light">
                                <font-awesome-icon icon="clock"/>
                                Espera retiro
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Finalizada'"
                                class="font-size-14 badge badge-custom badge-custom-green-light">
                                <font-awesome-icon icon="lock"/>
                                Finalizada
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Cancelada'"
                                class="font-size-14 badge badge-custom badge-custom-orange-light">
                                <i class="fa-regular fa-circle-xmark"></i>
                                Cancelada
                              </span>
                              <span 
                                v-if="ad.estado_solicitud.nombre === 'Rechazada'"
                                class="font-size-14 badge badge-custom badge-custom-red-light">
                                <i class="fa-regular fa-circle-xmark"></i>
                                Rechazada
                              </span>
                            </div>
                          </div> 
                        </a>
                    </div>
                  </li>
                </ul>
                <div class="row mt-3">
                  <div class="col-12">
                    
                    <button class="btn btn-custom-color-white border d-block" :disabled="total_parcial>=total_registros" @click="paginador()"> <i class="fa-solid fa-arrows-rotate color-blue pe-1"></i>Mostrar más ( {{total_parcial+" / "+total_registros}})</button>
                  </div> 
                </div>
              </div>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="card border-round-10 card-shadow border-0 mb-3">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <h5 class="font-main-bold color-gray mb-0">
                  <i class="fa-solid fa-circle-info color-secondary"></i>
                  Información  
                </h5>
                <span 
                  v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'En Proceso'"
                  class="font-size-14 badge badge-custom badge-custom-yellow-light">
                  <font-awesome-icon icon="clock"/>
                  En proceso
                </span>
                <span 
                  v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Confirmada'"
                  class="font-size-14 badge badge-custom badge-custom-blue-light">
                  <font-awesome-icon icon="check"/>
                  Corfirmada
                </span>
                <span 
                  v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Espera Retiro'"
                  class="font-size-14 badge badge-custom badge-custom-blue-light">
                  <font-awesome-icon icon="clock"/>
                  Espera retiro
                </span>
                <span 
                  v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Finalizada'"
                  class="font-size-14 badge badge-custom badge-custom-green-light">
                  <font-awesome-icon icon="lock"/>
                  Finalizada
                </span>
                <span 
                  v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Cancelada'"
                  class="font-size-14 badge badge-custom badge-custom-orange-light">
                  <i class="fa-regular fa-circle-xmark"></i>
                  Cancelada
                </span>
                <span 
                  v-if="selected_solicitud !== null && selected_solicitud.estado_solicitud.nombre === 'Rechazada'"
                  class="font-size-14 badge badge-custom badge-custom-red-light">
                  <i class="fa-regular fa-circle-xmark"></i>
                  Rechazada
                </span>
              </div>
            </div>

            <div 
              v-if="checks.length >= 2"
              class="alert alert-info text-center">
              <i class="fa-solid fa-circle-arrow-down"></i>
              ¿Cambiar el estado de las <strong>{{ checks.length }} solicitudes?</strong>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <v-select
                  :key="sel_selected_estados.id"
                  class="selvue-custom"
                  :clearable="false"
                  :options="data_selected_estados" 
                  v-model="sel_selected_estados"
                  placeholder="Categoría">
                  <span slot="no-options"></span>
                </v-select>  
              </div>
            </div>

            <ul 
              v-show="checks.length < 2"
              class="list-group list-group-flush font-size-14 scroll-custom-400">
              <li class="list-group-item">
                <div class="row">
                  <div class="col-6">
                      Nombre
                  </div>
                  <div class="col-6 text-end">
                    <p 
                      v-if="selected_solicitud !== null"
                      class="text-secondary mb-0">
                      {{ selected_solicitud.usuario.primer_nombre | capitalize }}
                      {{ selected_solicitud.usuario.apellido_paterno | capitalize }}
                      {{ selected_solicitud.usuario.apellido_materno | capitalize }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-6">
                    Rut
                  </div>
                  <div class="col-6 text-end">
                    <p class="text-secondary mb-0">
                      {{ selected_solicitud == null ? ""
                          : `${selected_solicitud.usuario.rut_usuario}-${selected_solicitud.usuario.dv_usuario}`
                      }}
                    </p>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-6">
                      Fecha de ingreso
                  </div>
                  <div class="col-6 text-end">
                    <p class="text-secondary mb-0">
                      {{ selected_solicitud == null ? ""
                        : `${selected_solicitud.usuario.fecha_ingreso}`
                      }}
                    </p>
                  </div>
                </div>
              </li>
              <li 
                v-for="(form, i) in isSelected()"
                :key="i"
                class="list-group-item">
                <div 
                  v-if="form.formulario_selector.tipo_dato !== 'Archivo'"
                  class="row">
                  <div class="col-5">
                    {{ form.formulario_selector.nombre_header }}
                  </div>
                  <div class="col-7 text-end">
                    <p class="text-secondary mb-0" 
                      v-html="dataTypeValor(form.formulario_selector.tipo_dato, form.valor)"
                      ></p>
                  </div>
                </div>
                <div 
                  v-if="form.formulario_selector.tipo_dato === 'Archivo'"
                  class="row">
                  <div class="col-12">
                    <i class="fa-solid fa-circle-arrow-down color-main"></i>
                    {{ form.formulario_selector.nombre_header }}
                  </div>
                  <div class="col-12 text-end">
                    <figure 
                      class="mb-0" 
                      v-html="dataTypeValor(form.formulario_selector.tipo_dato, form.valor)">
                    </figure>
                  </div>
                </div>
              </li> 
            </ul>

            <div class="row mt-3">
              <div class="col-12 text-end">
                <button
                  @click="changeStatus()"
                  v-if="selected_solicitud != null"
                  class="btn btn-custom-color-blue border"
                  :class="this.checks.length > 0 && sel_selected_estados.label != estado_selected.nombre ? '' : 'disabled' "
                >
                  <i class="fa-solid fa-floppy-disk"></i>
                  Guardar cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="closeStatus"
      />
      <Spinner v-if="show_spinner" />
      <Spinner v-if="show_spinner_benefi" />
  </section>

</template>

<script>
import Status from "../Modales/Status.vue";
import { mapActions, mapState } from "vuex";
//import { CHANGE_STATE_BENEF } from "@/apollo/mutations";
import {
  //ELEMENTS_TIPOS_SOLICITUD,
  //ESTADOS_RECONOCE,
  //LIST_ADMIN_BENEFICIO,
} from "@/apollo/queries";
import Spinner from "@/components/Spinner.vue";
import "export-json-excel";
export default {
  components: { Status, Spinner },
  data() {
    return {
      data_excel:[],
      fields_excel: [],
      name_excel: '',
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      user_id: this.$ls.get("user").id_usuario,
      pagina_actual: 1,
      ids_solicitudes: [""],
      // variable no ocupada se debe elimnar solicitud_selected
      solicitud_selected: {
        nombre: "Todas las solicitudes",
        id_estado_solicitud: "",
      },
      estado_selected: "En espera",
      dow_hover: false,
      open_estado_select_list: false,
      open_indicadores_select_list: false,
      user_default_img: require("@/assets/img/user_default_img.svg"),
      key_calendar_inicio: 0,
      key_calendar_termino: 0,
      calendar_inicio: new Date(),
      calendar_termino: new Date(),
      open_modal_detail: false,
      open_modal_status: false,
      modal_status_msg: false,
      modal_status: true,
      date_inicio: "Inicio",
      date_termino: "Término",
      img_dowload_w: require("@/assets/img/utilities/dowload-w.svg"),
      img_dowload_b: require("@/assets/img/utilities/dowload-b.svg"),
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      users: [
        { id: 1, name: "Pedro", date: new Date(), state: 1 },
        { id: 2, name: "Juan", date: new Date(), state: 3 },
      ],
      all_check: false,
      admin_list: [],
      checks: [],
      states: [],
      selected_solicitud: { estado_solicitud: { nombre: "" } },
      show_spinner: false,
      list_key: 0,
      id_param: this.$route.params.id,
      user_name: "",
      myli: { data: [], num: 0 },
      show_calendar: false,
      calendar_side: "",
      init_date: "Inicio",
      end_date: "Término",
      max_date: "",
      min_date: "",
      show_spinner_benefi:false,
      total_registros: 0,
      total_parcial: 0,

      //FF
      //sel_estados: {id: 0, label: "Todas las solicitudes", type: "Todas las solicitudes" },
      sel_estados: {id: 1, label: "En proceso", type: "En proceso" },

      //data_estados: [{id: 0, label: "Todas las solicitudes", type: "Todas las solicitudes" }],
      sel_selected_estados: {},
      data_selected_estados: []
    };
  },
  created: function () {
    this.getEstados();
  },
  computed: {
    ...mapState("benefitModule", [
      "benefit_list",
      "benefit_list_edits",
      "data_estados",
      "final_list",
      "paginas_solicitud",
      "termimno_carga"
    ]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  watch: {
    all_check(check) {
      if (check) {
        this.checkAllInputs();
      } else {
        this.checks = [];
        this.checks.push({
          solicitud: this.final_list[0].id_solicitud,
          user: this.final_list[0].usuario.id_usuario,
        });
        this.selectSoli(this.final_list[0], 0);
      }
    },
    sel_estados() {
      //this.getAdminListBenefit();
      console.log("sel_estados: ");
      this.pagina_actual = 1;
      this.getPaginas();
    },
    $route: {
      handler: function () {
        //this.final_list = [];
        //this.getAdminListBenefit();
        //this.pagina_actual = 1;
        this.sel_estados = {id: 1, label: "En proceso", type: "En proceso" };
        console.log("route");
        this.pagina_actual = 1;
        //this.getPaginas()
        this.id_param = this.$route.params.id;
        this.all_check = true;
        this.all_check = false;
        this.selected_solicitud = null;
        this.show_calendar = false;
        this.calendar_side = "";
        this.init_date = "Inicio";
        this.end_date = "Término";
        this.max_date = "";
        this.min_date = "";
      },
      immediate: true,
    },
    // Esto se agrega para poder controlar el tiempo de renderizado con la lista de beneficios
    termimno_carga() {
      this.show_spinner_benefi = false;
      this.total_parcial = this.final_list.length;
      this.getDataExcel();
    },
    // benefit_list() {
    //   // setTimeout(() => {
    //   //   this.list_key = this.list_key + 1;
    //   // }, 100);
    //   this.list_key = this.list_key + 1;
    // },
    //FF
    checks() {
      this.sel_selected_estados.id = this.estado_selected.id_estado_solicitud;
      this.sel_selected_estados.label = this.estado_selected.nombre;
      this.sel_selected_estados.type = this.estado_selected.__typename;
    }, 
  },
  mounted() {
    //this.changIdSolicitudAction(this.$route.params.id);
    if (this.$route.params.id != undefined && this.$route.params.id != null) {
      //console.log("ingreso por router params");
        //this.getAdminListBenefit();
    }
    else{
      console.log("no ingreso por router params");
      //this.final_list = [];
    }    
  },
  methods: {
    ...mapActions("benefitModule", [
      "getAdminListSoliAction",
      "changIdSolicitudAction",
      "getEstadosSolicitud",
      "getTipoBeneficio",
      "cambioEstadoSolicitud",
      "getPaginacionSolicitudes",
      "getListSolicitudes",
      "getListSolicitudesAppend"
    ]),
    selectedUserList(id) {
      var results = this.checks.filter((check) => {
        return check.solicitud == id;
      });
      return results.length > 0 && "bg-black-light";
      //console.log("id: ", id);
    },
    selectSoli(sol, i) {
      this.selectEstado(sol.estado_solicitud);
      this.$nextTick(() => {
        this.$refs.typeBox[i].focus();
      });
      this.selected_solicitud = sol;
      this.checks = [];
      this.checks.push({
        solicitud: sol.id_solicitud,
        user: sol.usuario.id_usuario,
      });
      //console.log(i)
    },
    // paginador: cambia la variable de las paginas para luego volver a buscar los datos de la siguiente pagina
    paginador(){
      this.pagina_actual = this.pagina_actual +1;
      if (this.pagina_actual > this.paginas_solicitud.length){
        this.pagina_actual = this.paginas_solicitud.length;
        //this.getPaginas();
      }
      else if(this.pagina_actual < 1){
        this.pagina_actual = 1;
        //this.getPaginas();
      }
      else if(this.pagina_actual <= this.paginas_solicitud.length){
        this.getPaginas();
      }
      console.log("revisando la pagina: ", this.pagina_actual);
      
    },
    async getEstados() {
      this.show_spinner = true;
      let data = {}
      await this.getEstadosSolicitud(data).then(resp=>{
        if(resp!=null){
          resp.forEach((element) =>  this.data_selected_estados.push({
              id: element.id_estado_solicitud,
              type: element.__typename,
              label: element.nombre
            }));
          }
          else{
            console.log("getEstados");
            // agregar el control de las fallas.
          }
          this.show_spinner = false;
      }).catch((err) => {
          console.log("err getEstadosSolicitud", err);
          this.show_spinner = false;
        });
    },
    // obtiene las paginas y luego llama al getListSolictudes, pasarle los Id asociados a la pagina actual
    async getPaginas(){
      this.show_spinner_benefi = true;
      //this.show_spinner = true;
      let data_ = {
        id_empresa: this.id_empresa,
        id_tipo_solicitud: this.$route.params.id,
        historico:
          this.init_date == "Inicio" || this.end_date == "Término"
            ? "0"
            : "1",
        fecha_inicio: this.init_date == "Inicio" ? "" : this.init_date,
        fecha_final: this.end_date == "Término" ? "" : this.end_date,
        nombre_usuario: this.user_name,
        id_estado: this.sel_estados.id == '' ? '0': this.sel_estados.id.toString(),
        limite: "40"
      }
      
      console.log("getPaginas: ",data_);
      await this.getPaginacionSolicitudes(data_).then(res=>{
          //console.log("acá revisando la respuesta del servidor: ", res);
          if (res)
          {
              res.map((data) => {
              if (data.pagina == this.pagina_actual) 
              {
                this.ids_solicitudes = data.solicitudes;
                let variables = {
                  id_empresa: this.id_empresa,
                  id_tipo_solicitud: this.$route.params.id,
                  id_solicitud: this.ids_solicitudes
                }
                if(this.pagina_actual>1){
                  this.getListSolicitudesAppend(variables).then(resp=>{
                  //console.log("data de en la getListSolicitudesAppend: ", resp);
                    if(resp!=null && resp){
                        if(resp.length < 1) this.selected_solicitud = null;
                        //if(resp.length>0) this.selectSoli(resp[0], 0);
                        //this.list_view(resp);
                        this.list_key = 1 + this.list_key;
                      }
                      else{
                        console.log("Sin datos para mostrar getListSolicitudesAppend");
                        this.show_spinner_benefi = false;
                        // agregar el control de las fallas.
                      }
                      //console.log("Paginación solicitudes:", this.ids_solicitudes);
                      
                  }).catch((err) => {
                    console.log("sin datos getListSolicitudesAppend", err);
                    //this.final_list = [];
                    this.show_spinner_benefi = false;
                  });
                  
                }
                else if(this.pagina_actual == 1)
                {
                  this.getListSolicitudes(variables).then(resp=>{
                  //console.log("data de en la getListSolicitudes: ", resp);
                    if(resp!=null && resp){
                        if(resp.length < 1) this.selected_solicitud = null;
                        if(resp.length>0) this.selectSoli(resp[0], 0);
                        //this.list_view(resp);
                        this.list_key = 1 + this.list_key;
                      }
                      else{
                        console.log("Sin datos para mostrar getListSolicitudes");
                        // agregar el control de las fallas.
                      }
                      //console.log("Paginación solicitudes:", this.ids_solicitudes);
                      //this.show_spinner_benefi = false;
                  }).catch((err) => {
                    console.log("sin datos getListSolicitudes", err);
                    //this.final_list = [];
                    this.show_spinner_benefi = false;
                  });
                }
              }
            });
          }
      }).catch(err=>{
        this.show_spinner_benefi = false;
        console.log("Falla getPaginacionSolicitudes: ", err);
      });
      this.total_registros = this.paginas_solicitud[0].total;
      
    },
    // Obtiene las solicitudes según el tipo de solicitud
    // async getAdminListBenefit() {
    //   let new_date = new Date(new Date())
    //   console.log("Fecha : ", new_date);
    //   this.show_spinner_benefi = true;
    //   var data = {
    //     id_empresa: this.id_empresa,
    //     id_tipo_solicitud: this.$route.params.id,
    //     historico:
    //       this.init_date == "Inicio" || this.end_date == "Término"
    //         ? "0"
    //         : "1",
    //     fecha_inicio: this.init_date == "Inicio" ? "" : this.init_date,
    //     fecha_final: this.end_date == "Término" ? "" : this.end_date,
    //     nombre_usuario: this.user_name,
    //     id_estado: this.sel_estados.id == '' ? '0': this.sel_estados.id.toString(),
    //     //id_estado: this.solicitud_selected.id_estado_solicitud == '' ? '0': this.solicitud_selected.id_estado_solicitud,
    //   };
    //   console.log("DATA: ", data); 
    //   await this.getAdminListSoliAction(data).then(resp=>{
    //     //console.log("data de en la instancia: ", resp);
    //     //this.final_list = resp;
    //     if(resp!=null){
    //         if(resp.length < 1) this.selected_solicitud = null;
    //         if(resp.length>0) this.selectSoli(resp[0], 0);
    //         //this.list_view(resp);
    //         this.list_key = 1 + this.list_key;
    //       }
    //       else{
    //         console.log("Falla");
    //         this.final_list = [];
    //         // agregar el control de las fallas.
    //       }
    //       this.show_spinner_benefi = false;
    //   }).catch((err) => {
    //       console.log("sin datos", err);
    //       //this.final_list = [];
    //       this.show_spinner_benefi = false;
    //     });
    //     //this.getDataExcel();
    // },
    isSelected() {
      if (this.selected_solicitud == null) {
        return [];
      } else {
        //console.log("-> : ",this.selected_solicitud.detalle_solicitud[0])
        return this.selected_solicitud.detalle_solicitud[0].valores_formulario;
      }
    },
    checkAllInputs() {
      this.checks = [];
      // for (let i = 0; i < this.benefit_list[this.id_param].length; i++) {
      //   this.checks.push({
      //     solicitud: this.benefit_list[this.id_param][i].id_solicitud,
      //     user: this.benefit_list[this.id_param][i].usuario.id_usuario,
      //   });
      // }
      // Se ocupa la misma variable final_list para aplicar los check de selección multiple
      for (let i = 0; i < this.final_list.length; i++) {
        this.checks.push({
          solicitud: this.final_list[i].id_solicitud,
          user: this.final_list[i].usuario.id_usuario,
        });
      }
    },
    openSelectIndicadores() {
      this.show_calendar = null; //cerrar calendario
      this.open_estado_select_list = false; // cerrar select de estado
      this.open_indicadores_select_list = !this.open_indicadores_select_list;
    },
    openSelectEstados() {
      this.show_calendar = null;
      this.open_indicadores_select_list = false; //Cerrar select solicitudes
      this.open_estado_select_list = !this.open_estado_select_list;
    },
    selectSolicitud(solicitud) {
      console.log("solicitud filtro por estado", solicitud);
      this.solicitud_selected = solicitud;
      //this.getAdminListBenefit();
      
      this.getPaginas();
      this.open_indicadores_select_list = false;
    },
    selectEstado(state) {
      this.estado_selected = state;
      //console.log("cambio de estado: ", this.estado_selected);
      this.open_estado_select_list = false;
    },
    handleUserImage(img) {
      if (img !== null && img !== "" && img !== undefined) {
        return img;
      } else {
        return this.user_default_img;
      }
    },
    clearDates(){
      this.end_date = 'Término';
      this.init_date = 'Inicio';
      //this.getAdminListBenefit();
      console.log("clearDates");
      this.getPaginas();
      this.show_calendar = false;
    },
    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.init_date != "Inicio" ? new Date(this.init_date) : "";
        this.max_date = this.end_date == "Término" ? "" : this.end_date;
        this.min_date = "";
      } else {
        var min = new Date(this.init_date);
        this.min_date = new Date(min.setDate(min.getDate() + 2));
        this.max_date = "";
        this.min_date = this.init_date == "Inicio" ? "" : this.min_date;
      }
    },
    onDayClick(day) {
      //console.log("onDayClick", day);
      if (this.calendar_side == "start-date") {
        this.init_date = day.id;
      } else {
        this.end_date = day.id;
      }
      if (this.init_date != "Inicio" && this.end_date != "Término") {
        //this.getAdminListBenefit();
        this.getPaginas();
        console.log("onDayClick");
      }
      setTimeout(() => {
        this.show_calendar = false;
      }, 100);
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },
    closeStatus() {
      this.open_modal_status = false;
    },
    saveChanges() {
      this.open_modal_status = true;
      this.modal_status_msg = "Cambios guardados con éxito";
    },
    //Formatear fecha
    dateNewFormat(date, type) {
      var day = new Date(date);
      var month = day.getMonth();

      if (type == 1) {
        return `${day.getDate()}/${
          month + 1 < 10 ? `0${month + 1}` : month + 1
        }/${day.getFullYear()}`;
      }
      if (type == 2) {
        return `${day.getDate()};${this.meses[month]}`;
      }
    },
    // cambiador de estados de solicitudes
    async changeStatus() {
      if (
        this.checks.length > 0 &&
        this.sel_selected_estados.label != this.estado_selected.nombre) {
        console.log("ENTRAR");
        this.show_spinner = true;
        for (let i = 0; i < this.checks.length; i++) {
          let data = {
            id_empresa: this.id_empresa,
            id_estado: this.sel_selected_estados.id,
            id_solicitud: this.checks[i].solicitud,
            id_usuario: this.checks[i].user,
            id_administrador: this.user_id,
          };
          //console.log("contenidos", data);
          await this.cambioEstadoSolicitud(data).then(resp=>{
            console.log("Data cambio de estado solicitud", resp);
          });
        }
        this.show_spinner = false;
        this.checks = [];
        this.all_check = false;
      }
    },
    list_view(li) {
      var li_ = li;
      var li_adds = this.benefit_list_edits[this.id_param];
      if (li_adds != undefined) {
        if (li_adds.length != this.myli.num) {
          for (let i = 0; i < li_adds.length; i++) {
            var ind = li.findIndex(
              (my_li) => my_li.id_solicitud == li_adds[i].id_solicitud
            );
            li_.splice(ind, 1, li_adds[i]);
          }
          this.myli.data = li_;
          this.myli.num = li_adds.length;
        }
      }
      this.final_list = li_;
      this.checks = [];
      // this.checks.push({
      //   solicitud: li_[0].id_solicitud,
      //   user: li_[0].usuario.id_usuario,
      // });
      //this.selectSoli(li_[0], 0);
    },
    dataTypeSelector(type) {
      //console.log("type: ",type)
      if (type == "Ticket") {
        return "check";
      }
      if (type == "Archivo") {
        return "archivo";
      } else {
        return;
      }
    },
    dataTypeValor(type, valor) {
      //console.log("valor: ",valor)
      if (type == "Ticket" && valor == "true") {
        return `<span class="badge badge-custom badge-custom-green-light font-size-14"><i class="fa-solid fa-check"></i></span>`;
      }
      if (type == "Ticket" && valor == "false") {
        return `<span class="badge badge-custom badge-custom-gray-light font-size-14"><i class="fa-solid fa-ban"></i></span>`;
      }
      if (type == "Archivo") {
        return `<img class="w-100 img-fluid img-thumbnail rounded" src="${valor}"/>`;
      } else {
        return valor;
      }
    },
    dateFormat(date){
      //console.log('actual_date: ',date);
      let new_date = new Date(date);
      let month = new_date.getMonth() + 1 < 10
            ? `0${new_date.getMonth() + 1}`
            : new_date.getMonth() + 1;
      let day = new_date.getDate() < 10 ? `0${new_date.getDate()}` : new_date.getDate();
      let year = new_date.getFullYear();
      return day+'-'+month+'-'+year;
    },
    // Exportar los datos a Excel de cada tipo de solicitud selecionada por el usuario
    async getDataExcel() {
      let data_content = [];
      let doc_name = null;
      //let name_reporte = "";
      let name_actual_date = '_'+this.dateFormat(new Date());
      // Se preparan los datos que no cambian en ninguna solicitud. 
      let fields = [
          { title: "RUT", name: "rut" },
          { title: "Nombres", name: "nombre" },
          { title: "Apellidos", name: "apellido" },
          { title: "Estado solicitud", name: "estado_solicitud" },
          { title: "Fecha solicitud", name: "fecha_solicitud" },
        ];
      let params_solcitudes = [];
      let is_params = true;

      // let is_education = this.final_list.some(fl => fl.tipo_solicitud.nombre_solicitud === 'Fondo Educacional');
      // let is_gas = this.final_list.some(fl => fl.tipo_solicitud.nombre_solicitud === 'Gas');
      // let is_salmon = this.final_list.some(fl => fl.tipo_solicitud.nombre_solicitud === 'Caja de Salmón');
      this.final_list.forEach(final => {
        let export_info_obj = {};
        let valor = {};
        //let valor_refactor = {};

        final.detalle_solicitud[0].valores_formulario.forEach(val => {
          let name_valor = 
            val.valor == "true" || val.valor == "false"
              ? val.valor == "true"
                ? "Si"
                : "No"
              : val.valor;
          //let title_dato = "title";
          let name_param = val.formulario_selector.nombre_header.trim().replaceAll(' ','_').replaceAll('-','_').replaceAll('.','').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          let valor_new = `{ "${name_param}" : "${name_valor}" }`;
          let valor_new_parce = JSON.parse(valor_new);
          valor = Object.assign(valor, valor_new_parce);
          //valor_refactor = Object.assign(valor_refactor, {title: val.formulario_selector.nombre_header, valor: name_valor});
          if(is_params){
            let new_params = { title: val.formulario_selector.nombre_header, name: name_param }
            fields.push(new_params);
            params_solcitudes.push(new_params);
          }
          ///valor = Object.assign(valor, generator_title);
        });
        // console.log("params solicitudes: ", params_solcitudes);
        // console.log("Valores: ", valor);
        // Variable que controla, que se creen los indices de cada solicitud (datos a descargar), solo una vez
        is_params = false;
        export_info_obj = {
            apellido: final.usuario.apellido_paterno+ ' '+final.usuario.apellido_materno,
            nombre: final.usuario.primer_nombre+' '+final.usuario.segundo_nombre,
            rut: final.usuario.rut_usuario+ '-'+final.usuario.dv_usuario,
            estado_solicitud: final.estado_solicitud.nombre,
            fecha_solicitud: final.fecha_creacion
          };
        doc_name = doc_name==null?'Reporte_'+ final.tipo_solicitud.nombre_solicitud + name_actual_date : doc_name;

        if(Object.keys(valor).length != 0 && valor.constructor === Object){
        
          params_solcitudes.forEach(param =>{
            export_info_obj[param.name] = valor[param.name];
            //console.log("revisando el nombre de los parametros: ", param.name);
          });
          data_content.push(export_info_obj);
        }
      });
      //console.log("doc_name: ", doc_name);
      //console.log("Valor de title para excel", fields);
      console.log("data_content: ", data_content);

      this.fields_excel = fields;
      this.data_excel = data_content;
      this.name_excel = doc_name;
    },
  },
    //FF
    filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>
